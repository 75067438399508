import React from 'react';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { objectToParamString } from '@utils/browser';
import { getLinks } from 'cache';
import PropTypes from 'prop-types';
import useSWR from 'swr';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import Link from '@ui/link/Link';

import Cms from '@components/Cms/Cms';
import useCms from '@components/Cms/useCms';
import useUser from '@components/User/useUser';

const ReportsButton = ({ mediaId, parentId }) => {
    const cms = useCms();
    const { isAdmin } = useUser();
    const showReports = isAdmin && (mediaId || parentId);
    const reportUrl = parentId
        ? `/media/reports?first=0&parentId=${parentId}`
        : `/media/reports?first=0&mediaId=${mediaId}`;
    const { data } = useSWR(showReports ? reportUrl : null);
    const reportCount = data?.totalCount || 0;

    return (
        <>
            {reportCount > 0 && (
                <Box sx={{ mr: 2, display: 'inline-block' }}>
                    <Link href={`${getLinks('adminReports')}?${objectToParamString({ mediaId, parentId }, true)}`}>
                        <IconButton aria-label={cms[850]}>
                            <ReportProblemRoundedIcon color="warning" />
                        </IconButton>
                        <Cms cmsId={852} args={[reportCount]} />
                    </Link>
                </Box>
            )}
        </>
    );
};

ReportsButton.propTypes = {
    mediaId: PropTypes.string,
    parentId: PropTypes.string,
};

export default ReportsButton;
