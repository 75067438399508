import React, { useMemo, useState } from 'react';

import { getLinks } from '@cache/index';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { mediaTypes } from '@utils/media';
import { toastSuccess } from '@utils/toast';
import { useRouter } from 'next/router';

import IconButton from '@ui/IconButton';
import ListItemIcon from '@ui/ListItemIcon';
import ListItemText from '@ui/ListItemText';
import Menu from '@ui/Menu';
import MenuItem from '@ui/MenuItem';

import useCms from '@components/Cms/useCms';

const ShareButton = ({ slug, mediaType }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const router = useRouter();
    const cms = useCms();
    const open = Boolean(anchorEl);

    const url = slug ? `${process.env.NEXT_PUBLIC_SITE_URL}/${slug}` : process.env.NEXT_PUBLIC_SITE_URL;
    const btnId = `${slug}-share-btn`;
    const menuId = `${slug}-share-btn-menu`;

    const options = useMemo(
        () => [
            {
                cmsId: 1005,
                icon: <MailOutlineRoundedIcon fontSize="small" />,
                onClick: () => {
                    window.open(`mailto:?body=${url}`);
                },
            },
            {
                cmsId: 1006,
                icon: <FacebookRoundedIcon fontSize="small" />,
                onClick: () => {
                    window.open(`http://www.facebook.com/sharer/sharer.php?u=${url}`);
                },
            },
            ...(global?.navigator && global?.navigator.clipboard
                ? [
                      {
                          cmsId: 1007,
                          icon: <ContentCopyRoundedIcon fontSize="small" />,
                          onClick: () => {
                              global?.navigator.clipboard.writeText(url);
                              toastSuccess(cms[1008]);
                          },
                      },
                  ]
                : []),
            ...(mediaType === mediaTypes.episode
                ? [
                      {
                          cmsId: 1011,
                          icon: <CodeIcon fontSize="small" />,
                          onClick: () => {
                              router.push(`${getLinks('embed')}/${slug}`);
                          },
                      },
                  ]
                : []),
        ],
        [cms, url, slug, router, mediaType]
    );

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = onAction => {
        onAction?.();
        handleClose();
    };

    return (
        <div>
            <IconButton
                aria-label={cms[1003]}
                color="secondary"
                id={btnId}
                aria-controls={cms[1004]}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <ShareRoundedIcon />
            </IconButton>
            <Menu
                id={menuId}
                MenuListProps={{
                    'aria-labelledby': btnId,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ zIndex: 6001 }}
            >
                {options.map(({ cmsId, icon, onClick }) => (
                    <MenuItem key={cmsId} onClick={() => handleItemClick(onClick)}>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{cms[cmsId]}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default ShareButton;
