import React from 'react';

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '@ui/FormControl';
import FormHelperText from '@ui/FormHelperText';
import Select from '@ui/inputs/Select/Select';
import selectPropTypes from '@ui/inputs/Select/Select.propTypes';

const FormSelect = ({ name, options, defaultValue, fullWidth, onChangeHandler, ...selectProps }) => {
    const formContext = useFormContext();

    return (
        <Controller
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
                const hasError = !!error;
                const handelChange = event => {
                    onChange(event);
                    if (onChangeHandler) {
                        onChangeHandler?.(event);
                    }
                };

                return (
                    <FormControl variant="filled" fullWidth={fullWidth} error={hasError} sx={{ mb: 2 }}>
                        <Select
                            options={options}
                            onChange={handelChange}
                            onBlur={onBlur}
                            value={value}
                            ref={ref}
                            variant="filled"
                            {...selectProps}
                        />
                        {hasError && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                );
            }}
            name={name}
            control={formContext.control}
            defaultValue={defaultValue || ''}
        />
    );
};

FormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    options: selectPropTypes.options,
    fullWidth: PropTypes.bool,
    onChangeHandler: PropTypes.func,
};

export default FormSelect;
