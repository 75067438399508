import React from 'react';

import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

import Box from '@ui/Box';

const Badge = styled(Box)(({ theme }) => ({
    borderRadius: 2,
    background: theme.palette.text.secondary,
    color: theme.palette.background.default,
    padding: '3px 6px',
    fontSize: 9,
    [theme.breakpoints.up('sm')]: {
        fontSize: 11,
    },
    textTransform: 'uppercase',
    fontWeight: 700,
}));

const ExplicitBadge = ({ sx = {}, labels }) => {
    const showFullText = useMediaQuery('(min-width:1230px)');

    if (!labels) {
        return <></>;
    }

    const { label, shortLabel } = labels;
    return <Badge sx={sx}>{showFullText ? label : shortLabel}</Badge>;
};

export default ExplicitBadge;
