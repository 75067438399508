/* eslint-disable max-lines */
import React, { useState } from 'react';

import AddOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useAsyncHandler } from '@utils/hooks';

import CircularProgress from '@ui/CircularProgress';
import IconButton from '@ui/IconButton';

import useApi from '@components/api/useApi';
import useCms from '@components/Cms/useCms';
import AddToMyListDialog from '@components/MyList/AddToMyListDialog';
import MyPlaylistContextProvider from '@components/MyPlaylist/MyPlaylistContextProvider';
import useRegisterDialog from '@components/RegisterDialog/useRegisterDialog';
import useUser from '@components/User/useUser';

const MyListButton = ({ listId, mediaId, isOnMyList, reload }) => {
    const cms = useCms();
    const api = useApi();
    const { user } = useUser();
    const { openRegisterDialog } = useRegisterDialog();

    const [addToListDialogOpen, setAddToListDialogOpen] = useState(false);
    const [loading, setLoading] = useState();

    const [handleAddNewList, addNewListLoading] = useAsyncHandler(
        {
            handler: async ({ name }) => {
                setLoading(true);
                const { id } = await api.post('/my/playlists', { name });
                if (id) {
                    await api.post(`/my/playlists/${id}/items`, {
                        mediaId,
                        name,
                    });
                }

                setLoading(false);
                closeAddToMyListDialog();
            },
            stopLoadingOnSuccess: true,
            muteNotifications: true,
        },
        []
    );

    const [handleAddToMyList, addToMyListLoading] = useAsyncHandler(
        {
            handler: async ({ playListId }) => {
                setLoading(true);
                await api.post(`/my/playlists/${playListId}/items`, {
                    predecessorId: playListId,
                    mediaId,
                });

                setLoading(false);
                closeAddToMyListDialog();
            },
            stopLoadingOnSuccess: true,
            muteNotifications: true,
        },
        []
    );

    const [handleRemoveFromMyList] = useAsyncHandler({
        handler: async () => {
            setLoading(true);
            await api.deleteRequest(`/my/playlists/${listId}/items/${mediaId}`);
            await reload();
            setLoading(false);
        },
        stopLoadingOnSuccess: true,
        muteNotifications: true,
    });

    const openAddToMyListDialog = () => setAddToListDialogOpen(true);
    const closeAddToMyListDialog = () => setAddToListDialogOpen(false);

    const addToMyList = () => {
        if (user) {
            openAddToMyListDialog();
        } else {
            openRegisterDialog();
        }
    };

    const handleAddToMyListOrCreate = data => {
        if (data?.name?.length > 0) {
            handleAddNewList(data);
            return;
        }

        handleAddToMyList(data);
    };

    return (
        <>
            {loading ? (
                <CircularProgress color="inherit" size={24} sx={{ ml: 2 }} />
            ) : (
                <>
                    {isOnMyList && listId ? (
                        <IconButton aria-label={cms[352]} onClick={handleRemoveFromMyList} disabled={!!loading}>
                            <RemoveCircleOutlineRoundedIcon color="secondary" />
                        </IconButton>
                    ) : (
                        <IconButton aria-label={cms[165]} onClick={addToMyList} disabled={!!loading}>
                            <AddOutlineIcon color="secondary" />
                        </IconButton>
                    )}
                </>
            )}
            {addToListDialogOpen && (
                <MyPlaylistContextProvider>
                    <AddToMyListDialog
                        isOpen={addToListDialogOpen}
                        onAddNewList={handleAddToMyListOrCreate}
                        onClose={closeAddToMyListDialog}
                        loading={addToMyListLoading || addNewListLoading}
                    />
                </MyPlaylistContextProvider>
            )}
        </>
    );
};

export default MyListButton;
