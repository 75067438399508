/* eslint-disable max-lines */
import React from 'react';
import { useMemo } from 'react';

import { convertTimeStampToDate } from '@utils/date';
import { useIsDesktop } from '@utils/hooks';
import { isContentLocked, mediaTypes } from '@utils/media';

import Box from '@ui/Box';

import FavoriteButton from '@components/Favorites/FavoriteButton/FavoriteButton';
import ReportButton from '@components/Media/ReportButton';
import MyListButton from '@components/MyList/MyListButton';
import AdInfo from '@components/RadiantPlayer/components/AdInfo';
import useRadiantPlayerContext from '@components/RadiantPlayer/useRadiantPlayer';
import ShareButton from '@components/ShareButton';

import ExplicitBadge from './ExplicitBadge';
import MediaItemActionsDate from './MediaItemActionsDate';
import MediaItemActionsDuration from './MediaItemActionsDuration';
import MediaItemActionsMetaContainer from './MediaItemActionsMetaContainer';
import MediaItemActionsPodcastActionsContainer from './MediaItemActionsPodcastActionsContainer';
import { getDurationLabel } from './mediaItemActionUtils';
import PlayButton from './PlayButton';
import ReportsButton from './ReportsButton';
import StatsButton from './StatsButton';

// TODO cleanup with statistics page
const showStats = false;

const MediaItemActions = ({ mediaData, mediaStartTime, isOnMyList = false, listId, reload, sx = {}, small }) => {
    const isDesktop = useIsDesktop();
    const mediaId = mediaData?.id;

    const { currentTrack, isAdPlaying, playingAtSeconds } = useRadiantPlayerContext();

    const isPodcast = mediaData?.type === mediaTypes.podcast;
    const isEpisode = mediaData?.type === mediaTypes.episode;
    const isActive = mediaData?.id === currentTrack?.id;
    const isPublished = mediaData?.state === 'published';
    const isShowCompactVersion = isPodcast;
    const isMatureContent = mediaData?.isMatureContent;
    const matureContentLabel = mediaData?.badges?.[0];
    const showSkipAd = isAdPlaying && isActive;
    const showPublishDate = !isShowCompactVersion && isPublished && mediaData?.type !== mediaTypes.radio;
    const showReports = isPodcast || isEpisode;

    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);
    const isShowActions = mediaData?.streamingUrls?.length > 0 || isLockedMemo;

    return (
        <>
            {isShowCompactVersion ? (
                <MediaItemActionsMetaContainer>
                    {isMatureContent && <ExplicitBadge labels={matureContentLabel} />}
                    <FavoriteButton mediaId={mediaId} isFavoriteDefault={mediaData.isFavorite} reload={reload} />
                    <ShareButton slug={mediaData?.slug} mediaType={mediaData?.type} />
                    {showReports && <ReportsButton mediaId="" parentId={mediaId} />}
                </MediaItemActionsMetaContainer>
            ) : (
                <>
                    {isShowActions && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', minHeight: 40, ...sx }}>
                            <MediaItemActionsMetaContainer>
                                <div>
                                    <PlayButton
                                        mediaData={mediaData}
                                        mediaStartTime={mediaStartTime}
                                        listId={listId}
                                        small={small}
                                    />
                                </div>
                                {showSkipAd && <AdInfo sx={{ ml: 2 }} />}
                                {!showSkipAd && isPublished && mediaData.type === mediaTypes.episode && (
                                    <MediaItemActionsDuration variant="body1">
                                        {isAdPlaying || !isActive
                                            ? mediaData?.duration?.formatted
                                            : getDurationLabel(
                                                  isDesktop,
                                                  playingAtSeconds,
                                                  mediaData?.duration?.formatted
                                              )}
                                    </MediaItemActionsDuration>
                                )}
                                {!showSkipAd && isMatureContent && <ExplicitBadge labels={matureContentLabel} />}
                            </MediaItemActionsMetaContainer>
                            {!showSkipAd && (
                                <MediaItemActionsPodcastActionsContainer>
                                    <>
                                        {showReports && !small && <ReportsButton mediaId={mediaId} parentId="" />}
                                        {showStats && <StatsButton slug={mediaData?.slug} />}
                                        {isPublished && (
                                            <FavoriteButton
                                                mediaId={mediaId}
                                                isFavoriteDefault={mediaData.isFavorite}
                                                reload={reload}
                                            />
                                        )}
                                        {mediaData.type === mediaTypes.episode && (
                                            <MyListButton
                                                listId={listId}
                                                mediaId={mediaId}
                                                isOnMyList={isOnMyList}
                                                reload={reload}
                                            />
                                        )}
                                        {mediaData?.type === mediaTypes.episode && (
                                            <ReportButton mediaData={mediaData} />
                                        )}
                                        {isPublished && (
                                            <ShareButton slug={mediaData?.slug} mediaType={mediaData?.type} />
                                        )}
                                    </>
                                </MediaItemActionsPodcastActionsContainer>
                            )}
                        </Box>
                    )}
                </>
            )}
            {showPublishDate && (
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    {showPublishDate && (
                        <MediaItemActionsDate variant="body2">
                            {convertTimeStampToDate(mediaData?.publishedAt)}
                        </MediaItemActionsDate>
                    )}
                </Box>
            )}
        </>
    );
};

export default MediaItemActions;
