import { styled } from '@mui/system';

import Typography from '@ui/Typography';

const MediaItemActionsDuration = styled(Typography)(({ theme }) => ({
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
}));

export default MediaItemActionsDuration;
