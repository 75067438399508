import React, { useEffect, useState } from 'react';

import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { useAsyncHandler } from '@utils/hooks';

import IconButton from '@ui/IconButton';

import useApi from '@components/api/useApi';
import useCms from '@components/Cms/useCms';
import useRegisterDialog from '@components/RegisterDialog/useRegisterDialog';
import useUser from '@components/User/useUser';

const FavoriteButton = ({ mediaId, isFavoriteDefault, reload }) => {
    const cms = useCms();
    const api = useApi();
    const { openRegisterDialog } = useRegisterDialog();
    const { user } = useUser();
    const [isFavorite, setIsFavorite] = useState(isFavoriteDefault);

    useEffect(() => {
        setIsFavorite(isFavoriteDefault);
    }, [isFavoriteDefault]);

    const [handleAddToFavorite] = useAsyncHandler({
        handler: async mediaId => {
            const res = await api.post(`/my/favorites/`, { mediaId });
            if (isFavorite !== res.isFavorite) {
                setIsFavorite(res.isFavorite);
            }

            if (reload) {
                reload();
            }
        },
        stopLoadingOnSuccess: true,
        muteNotifications: true,
    });

    const [handleRemoveFromFavorite] = useAsyncHandler({
        handler: async mediaId => {
            const res = await api.deleteRequest(`/my/favorites/${mediaId}`);
            if (isFavorite !== res.isFavorite) {
                setIsFavorite(res.isFavorite);
            }

            if (reload) {
                reload();
            }
        },
        stopLoadingOnSuccess: true,
        muteNotifications: true,
    });

    const handleFavorite = () => {
        if (!user) {
            openRegisterDialog();
            return;
        }

        if (isFavorite) {
            handleRemoveFromFavorite(mediaId);
        } else {
            handleAddToFavorite(mediaId);
        }

        setIsFavorite(!isFavorite);
    };

    return (
        <IconButton aria-label={cms[165]} onClick={handleFavorite}>
            {isFavorite ? <FavoriteRoundedIcon color="secondary" /> : <FavoriteBorderRoundedIcon color="secondary" />}
        </IconButton>
    );
};

export default FavoriteButton;
