import React, { useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import Box from '@ui/Box';
import Dialog from '@ui/Dialog';
import DialogContent from '@ui/DialogContent';
import DialogTitle from '@ui/DialogTitle';
import FormSelect from '@ui/form/FormSelect';
import FormTextField from '@ui/form/FormTextField';
import Button from '@ui/inputs/Button';

import useCms from '@components/Cms/useCms';
import Form from '@components/Form/Form';
import useMyPlaylist from '@components/MyPlaylist/useMyPlaylist';

const AddToMyListDialog = ({ isOpen, onAddNewList, onClose, loading }) => {
    const cms = useCms();
    const nameInputRef = useRef();
    const [showAddNewList, setShowAddNewList] = useState(false);
    const { playList } = useMyPlaylist();
    const isEmptyPlayList = playList?.length === 0;
    const showNameField = showAddNewList || isEmptyPlayList;

    const schema = useMemo(
        () =>
            yup.object().shape({
                ...(!isEmptyPlayList && {
                    playListId: yup.string().required(cms[470]),
                }),
                ...(showNameField && {
                    name: yup.string().required(cms[425]),
                }),
            }),
        [cms, showNameField, isEmptyPlayList]
    );

    const handleOnClose = () => {
        setShowAddNewList(false);
        onClose();
    };

    const handleOnSubmit = data => {
        setShowAddNewList(false);
        onAddNewList(data);
    };

    const createNewList = useMemo(
        () => ({
            name: cms[801],
            value: 'createNewList',
        }),
        [cms]
    );

    const options = useMemo(() => {
        const optionList = playList?.map(({ name, id }) => ({
            value: id,
            name,
        }));
        return [...optionList, createNewList];
    }, [playList, createNewList]);

    const handleFormSelect = e => {
        setShowAddNewList(e?.target?.value === createNewList?.value);
    };

    const focusToNewList = () => {
        if (showAddNewList) {
            nameInputRef.current.focus();
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleOnClose}>
            <DialogTitle>{cms[471]}</DialogTitle>
            <Form onSubmit={handleOnSubmit} schema={schema}>
                <DialogContent>
                    {!isEmptyPlayList && (
                        <FormSelect
                            disabled={loading}
                            label={cms[474]}
                            name="playListId"
                            options={options}
                            defaultValue={options[0]?.value}
                            fullWidth
                            onChangeHandler={handleFormSelect}
                            MenuProps={{
                                TransitionProps: {
                                    onExited: focusToNewList,
                                },
                            }}
                        />
                    )}
                    {showNameField && (
                        <FormTextField ref={nameInputRef} label={cms[902]} disabled={loading} name="name" />
                    )}
                    <Box textAlign="right">
                        <Button variant="outlined" onClick={handleOnClose} disabled={loading} sx={{ mr: 2 }}>
                            {cms[472]}
                        </Button>
                        <Button type="submit" loading={loading} disabled={loading}>
                            {cms[473]}
                        </Button>
                    </Box>
                </DialogContent>
            </Form>
        </Dialog>
    );
};

AddToMyListDialog.propTypes = {
    isOpen: PropTypes.bool,
    onAddNewList: PropTypes.func,
    onClose: PropTypes.func,
    loading: PropTypes.bool,
    list: PropTypes.array,
};

export default AddToMyListDialog;
