import { styled } from '@mui/system';

import CardMedia from '@ui/CardMedia';

const MediaItemActionsMetaContainer = styled(CardMedia)({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export default MediaItemActionsMetaContainer;
