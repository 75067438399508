import { styled } from '@mui/system';

const MediaListItemCoverContainer = styled('div', { shouldForwardProp: prop => prop !== 'noClick' })(
    ({ theme, noClick }) => ({
        width: 46,
        minWidth: 46,
        minHeight: 46,
        height: 46,
        padding: 0,
        cursor: noClick ? 'default' : 'pointer',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: 60,
            minWidth: 60,
            minHeight: 60,
            height: 60,
        },
        [theme.breakpoints.up('md')]: {
            width: 100,
            minWidth: 100,
            minHeight: 100,
            height: 100,
            marginBottom: 0,
        },
    })
);

export default MediaListItemCoverContainer;
