import PropTypes from 'prop-types';

const selectPropTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        })
    ),
};

export default selectPropTypes;
