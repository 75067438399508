import { getLinks } from 'cache';

export const getAdminUrl = (mediaType, mediaId, parentId) => {
    const urls = {
        host: `${getLinks('adminHosts')}/${mediaId}`,
        radio: `${getLinks('adminRadios')}/${mediaId}`,
        category: `${getLinks('adminCategories')}/${mediaId}`,
        podcast: `${getLinks('adminPodcasts')}/${mediaId}`,
        episode: `${getLinks('adminPodcasts')}/${parentId}/episodes/${mediaId}`,
    };

    return urls[mediaType];
};
