import { styled } from '@mui/system';

import CardMedia from '@ui/CardMedia';

const MediaItemActionsPodcastActionsContainer = styled(CardMedia)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export default MediaItemActionsPodcastActionsContainer;
