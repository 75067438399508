import React from 'react';

import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { getAdminUrl } from '@utils/links';
import PropTypes from 'prop-types';

import IconButton from '@ui/IconButton';
import Link from '@ui/link/Link';

import useUser from '@components/User/useUser';

const AdminEditLink = ({ mediaData }) => {
    const { isAdmin } = useUser();

    if (!isAdmin) {
        return null;
    }

    const href = getAdminUrl(mediaData?.type, mediaData?.id, mediaData?.parentMedia?.id);

    return (
        <Link color="inherit" underline="none" href={href}>
            <IconButton sx={{ ml: 1 }} color="secondary" size="small">
                <EditRoundedIcon sx={{ fontSize: 'inherit' }} />
            </IconButton>
        </Link>
    );
};

AdminEditLink.propTypes = {
    mediaData: PropTypes.object,
};

export default AdminEditLink;
