import { useListQuery } from '@utils/query';

const useMyPlaylistsQuery = ({ filters, disabled } = {}) => {
    return useListQuery({
        endpoint: '/my/playlists',
        filters,
        disabled,
        muteNotifications: true,
    });
};

export default useMyPlaylistsQuery;
