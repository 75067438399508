import React, { useImperativeHandle, useRef } from 'react';

import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '../inputs/TextField';

const FormTextField = React.forwardRef((props, fRef) => {
    const { name, defaultValue, label, ...textFieldProps } = props;
    const inputRef = useRef();
    const formContext = useFormContext();

    useImperativeHandle(fRef, () => ({
        focus: () => {
            inputRef.current.focus();
        },
    }));

    return (
        <Controller
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                return (
                    <TextField
                        error={!!error}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputRef={inputRef}
                        helperText={error?.message}
                        label={label}
                        {...textFieldProps}
                        sx={label ? { mb: 2 } : { mb: 0, ['& input']: { paddingTop: '8px' } }}
                    />
                );
            }}
            name={name}
            control={formContext.control}
            defaultValue={defaultValue || ''}
        />
    );
});

FormTextField.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
};

export default FormTextField;
