import React from 'react';

import { Select as MuiSelect } from '@mui/material';
import { useId } from 'utils/hooks';

import InputLabel from '@ui/InputLabel';
import MenuItem from '@ui/MenuItem';

import selectPropTypes from './Select.propTypes';

const Select = React.forwardRef(({ label, options, ...props }, ref) => {
    const labelId = useId('select-label');

    return (
        <>
            {label && (
                <InputLabel id={labelId} disableAnimation>
                    {label}
                </InputLabel>
            )}
            <MuiSelect ref={ref} labelId={labelId} {...props}>
                {options &&
                    options.map(({ value, name }, index) => (
                        <MenuItem key={index} value={value}>
                            {name}
                        </MenuItem>
                    ))}
            </MuiSelect>
        </>
    );
});

Select.displayName = 'Select';

Select.propTypes = selectPropTypes;

export default Select;
