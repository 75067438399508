import React from 'react';

import { CardMedia as MuiCardMedia } from '@mui/material';

const CardMedia = props => {
    const { children, ...rest } = props;
    return <MuiCardMedia {...rest}>{children}</MuiCardMedia>;
};

export default CardMedia;
