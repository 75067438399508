import { styled } from '@mui/system';

import Typography from '@ui/Typography';

const MediaItemActionsDate = styled(Typography)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap',
        display: 'block',
        marginRight: theme.spacing(1),
    },
}));

export default MediaItemActionsDate;
