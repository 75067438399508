import React from 'react';

import useMyPlaylistsQuery from '@components/MyPlaylist/hooks/useMyPlaylistsQuery';
import useUser from '@components/User/useUser';

import PlaylistContext from './MyPlaylistContext';

const MyPlaylistContextProvider = ({ children }) => {
    const { user } = useUser();
    const [playList, playListLoading, playListTotalCount, playListLoadMore, playListReload] = useMyPlaylistsQuery({
        disabled: !user,
    });

    return (
        <PlaylistContext.Provider
            value={{
                playList,
                playListLoading,
                playListTotalCount,
                playListLoadMore,
                playListReload,
            }}
        >
            {children}
        </PlaylistContext.Provider>
    );
};

export default MyPlaylistContextProvider;
