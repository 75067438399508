export const getDurationLabel = (isDesktop, currentSeconds, durationFormatted) => {
    const hours = Math.floor(currentSeconds / 3600);
    const hoursString = String(hours);
    const minutes = Math.floor((currentSeconds - hours * 3600) / 60);
    const minutesString = String(minutes).padStart(2, '0');
    const seconds = currentSeconds - hours * 3600 - minutes * 60;
    const secondsString = String(seconds).padStart(2, '0');

    const minutesSeconds = `${minutesString}:${secondsString}`;
    const currentTimeFormatted = hours ? `${hoursString}:${minutesSeconds}` : minutesSeconds;

    return isDesktop ? `${currentTimeFormatted}/${durationFormatted}` : currentTimeFormatted;
};
